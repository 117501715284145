'use client'
import React, { useState, useEffect, type ComponentPropsWithRef, type ReactElement } from 'react'
import useCollapse from 'react-collapsed'
import clsx from 'clsx'
import Content from '@components/content'
import { Icon } from '@shc/ui'

interface AccordionProps extends Omit<ComponentPropsWithRef<'div'>, 'title'> {
  title: string | ReactElement
  onToggle?: (data: OnToggleParams) => void
  onExpand?: () => void
  onCollapse?: () => void
  size?: 'sm' | 'md'
  isOpen?: boolean
  iconColor?: 'primary' | 'gray-700'
}

export interface OnToggleParams {
  isExpanded: boolean
}

const Accordion = ({
  title,
  onToggle,
  onExpand,
  onCollapse,
  size = 'md',
  className,
  children,
  isOpen = false,
  iconColor = 'primary',
  ...props
}: AccordionProps) => {
  const [isExpanded, setIsExpanded] = useState(isOpen)
  const { getCollapseProps, getToggleProps } = useCollapse({ isExpanded })

  useEffect(() => {
    setIsExpanded(isOpen)
  }, [isOpen])

  const toggleAccordion = () => {
    setIsExpanded((prevState) => {
      const newIsExpanded = !prevState
      if (typeof onToggle === 'function') {
        onToggle({
          isExpanded: newIsExpanded,
        })
      }
      if (typeof onExpand === 'function' && newIsExpanded) {
        onExpand()
      }
      if (typeof onCollapse === 'function' && !newIsExpanded) {
        onCollapse()
      }
      return newIsExpanded
    })
  }

  return (
    <div className={clsx('flex flex-col border-b w-full', className)} {...props}>
      <button
        className={clsx(
          'py-5 box-border appearance-none cursor-pointer focus:outline-2 outline-primary outline-offset-2 flex',
          size != 'sm' && 'justify-center items-center',
          size === 'sm' && 'justify-start items-center py-0 pb-3'
        )}
        {...getToggleProps({ onClick: toggleAccordion })}>
        <div
          className={clsx(
            'text-base font-semibold flex-grow text-left mr-8',
            size === 'sm' && 'flex-grow-0 md:flex-grow md:text-right mr-1.5'
          )}>
          {title}
        </div>
        <Icon
          icon="chevron-down"
          className={clsx(
            isExpanded && '-rotate-180',
            'transform duration-200 flex-shrink h-4 text-primary',
            iconColor === 'primary' && 'text-primary',
            iconColor === 'gray-700' && 'text-gray-700',
            size === 'sm' && 'text-gray-700'
          )}
        />
      </button>

      <div {...getCollapseProps()}>
        <Content className="rich-text mb-5">{children}</Content>
      </div>
    </div>
  )
}

export default Accordion
