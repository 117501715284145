'use client'
import React, { type ComponentPropsWithRef, useRef, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, A11y } from 'swiper/modules'
import { richTextRender } from '@lib/rich-text-base'
import { generateBaseMediaQWidths } from '@lib/services/image-service'
import type SwiperCore from 'swiper'
import CaptionedMedia from '@cms/components/captioned-media'
import type { TypeCaptionedMediaFields } from '@lib/generated-types'
import type { Entry } from 'contentful'
import { Icon } from '@shc/ui'
import useAnalytics from '@hooks/use-analytics'

interface MediaCarouselProps extends ComponentPropsWithRef<'div'> {
  media: Entry<TypeCaptionedMediaFields>[]
}

const MediaCarousel = ({ media }: MediaCarouselProps) => {
  const { track } = useAnalytics()
  const prevRef = useRef(null)
  const nextRef = useRef(null)
  const [activeIndex, setActiveIndex] = useState<number>(0)

  const onBeforeInit = (Swiper: SwiperCore): void => {
    if (typeof Swiper.params.navigation !== 'boolean') {
      const navigation = Swiper.params.navigation
      if (navigation) {
        navigation.prevEl = prevRef.current
        navigation.nextEl = nextRef.current
      }
    }
  }

  return (
    <Swiper
      onSlideChange={(swiper) => {
        setActiveIndex(swiper.activeIndex)
        // TODO: add block context
        track({
          event: { name: 'component_scroll', data: {} },
          contexts: [
            {
              name: 'component',
              data: {
                component_text: 'MediaCarousel',
              },
            },
          ],
        })
      }}
      loop={false}
      watchOverflow={false}
      a11y={{
        enabled: true,
        firstSlideMessage: 'This is the first slide',
        lastSlideMessage: 'This is the last slide',
      }}
      keyboard={{
        enabled: true,
      }}
      modules={[Navigation, A11y]}
      onBeforeInit={onBeforeInit}
      slidesPerView={1}
      spaceBetween={20}
      breakpoints={{
        320: {
          slidesPerView: 1.2,
        },
        768: {
          slidesPerView: 1,
        },
      }}>
      {media?.map((slides: Entry<TypeCaptionedMediaFields>, idx: number) => {
        return (
          <SwiperSlide key={idx}>
            <CaptionedMedia
              {...slides.fields}
              extras={generateBaseMediaQWidths(8)}
              imageClassName="w-full"
              showCaption={false}
            />
            <div className="text-xs sm:text-sm text-left pt-3 caption">
              <strong>
                {idx + 1} of {media.length}
              </strong>
              {media[idx]?.fields?.caption && ' : '}
              {media[idx]?.fields?.caption && richTextRender(media[idx].fields.caption)}
            </div>
          </SwiperSlide>
        )
      })}
      {media.length !== 1 && (
        <div className="hidden flex-row justify-between md:block">
          <button
            ref={prevRef}
            className="swiperPrev h-10 w-10 absolute top-[40%] z-10 cursor-pointer	bg-white rounded-full flex justify-center items-center text-[#42484D] left-0 text-lg disabled:text-[#42484D2E] ml-3"
            disabled={activeIndex === 0 ? true : false}>
            <Icon icon="chevron-left" />
          </button>
          <button
            ref={nextRef}
            className="swiperNext h-10 w-10 absolute top-[40%] z-10 cursor-pointer	bg-white rounded-full flex justify-center items-center text-[#42484D] right-0 text-lg disabled:text-[#42484D2E] mr-3"
            disabled={media.length - 1 === activeIndex ? true : false}>
            <Icon icon="chevron-right" />
          </button>
        </div>
      )}
    </Swiper>
  )
}

export default MediaCarousel
